import $ from 'jquery'
import 'select2/dist/css/select2.css'
import 'select2'
import flatpickr from "flatpickr"
import moment from 'moment'
window.moment = moment



$(document).on('ready turbolinks:load', function() {
  $('.dropdown-toggle').dropdown();
})


$(document).on('turbolinks:before-cache', function() {     // this approach corrects the select 2 to be duplicated when clicking the back button.
  $('.js-source-states').select2('destroy');
} );

$(document).ready( ready );                  //... once document ready
$(document).ajaxComplete( ready );           //... once ajax is complete
$(document).on('turbolinks:load', ready );   //... once a link is clicked

function ready() {
    $('.js-source-states').select2();
};