// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap/dist/js/bootstrap.bundle'
import 'jquery/dist/jquery.slim'
import 'popper.js/dist/esm/popper'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('moment')

require("select2")
import '../stylesheets/application'

import ujs from "@rails/ujs"
import "../states"

require("flatpickr/dist/flatpickr.css")
import "material-design-icons-iconfont"

require.context('../images', true)
require("@rails/activestorage").start()

require("chartkick")
Chartkick.configure({language: "de"})
//require("chart.js")

require('clipboard.js/dist/clipboard')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
